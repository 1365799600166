import React from 'react';
import ImageContext from '../components/ImageContext';
import Img from 'gatsby-image';
import { css } from '@emotion/core';

const warningStyle = theme => css`
  display: block;
  font-size: 2em;
  color: ${theme.cError};
  margin-bottom: 2em;
`;

const Image = ({ src, alt, ...props }) =>
  alt ? (
    <ImageContext.Consumer>
      {images => (
        <Img
          fluid={images.filter(image => image.url === src)[0].fluid}
          {...props}
        />
      )}
    </ImageContext.Consumer>
  ) : (
    <span css={warningStyle}>
      Image {src} requires alt descriptive text to display.
    </span>
  );
export default Image;
