import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { css } from '@emotion/core';

const itemStyle = theme => css`
  li {
    margin-bottom: 2em;
  }

  a {
    color: ${theme.cBlack};
    display: flex;
    align-items: center;
    padding: 0.5em;
    background-color: ${theme.cLight};
    border-radius: 1em;
    text-decoration: none;
    box-shadow: 0 0.2em 1em rgba(59, 43, 91, 0.7);

    &:focus {
      outline: 4px solid ${theme.cDark};
      outline-offset: 0.5em;
      background-color: ${theme.cNormal};
    }

    &:hover {
      background-color: ${theme.cNormal};

      .link-content {
        span:nth-of-type(odd) {
          text-decoration: none;
        }
      }
    }

    ${theme.mqMax['medium']} {
      font-size: 0.8em;
    }
  }

  .link-content {
    display: flex;
    flex-direction: column;
    margin-left: 1em;

    span:nth-of-type(odd) {
      text-decoration: underline;
      margin-bottom: 0.3em;
    }

    span:nth-of-type(even) {
      font-size: 0.7em;
      font-weight: normal;
      font-style: italic;
      ${theme.mqMax['medium']} {
        font-size: 0.6em;
      }
    }
  }

  .gatsby-image-wrapper {
    border: 1px solid ${theme.cDark};
    max-width: 2em;
    border-radius: 0.5em;
    min-width: 100px;
  }
`;

const ItemList = ({ heading, items }) => (
  <section css={itemStyle} aria-labelledby="listHeading">
    <h2 id="listHeading">{heading}</h2>
    <ul>
      {items.map(({ title, slug, thumbnail, datePublished }) => {
        return (
          <li key={slug}>
            <h3>
              <Link to={`/${slug}`}>
                <Img fluid={thumbnail.fluid} alt="" />
                <div className="link-content">
                  <span>{title}</span>
                  <span>- Published on {datePublished}</span>
                </div>
              </Link>
            </h3>
          </li>
        );
      })}
    </ul>
  </section>
);

export default ItemList;
