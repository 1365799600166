import React from 'react';
import { css } from '@emotion/core';
import { Link } from 'gatsby';

const footerStyle = theme => css`
  display: block;
  background-color: ${theme.cBlack};
  padding: 0 1rem;
  color: ${theme.cWhite};

  .content-container {
    white-space: nowrap;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 2rem 0;
    margin: 0 auto;
    max-width: 45em;
    background-color: ${theme.cBlack};
    color: ${theme.cWhite};
    font-size: 1.5rem; // Sad browsers
    font-size: calc(0.75rem + 1vw);

    &::after {
      position: absolute;
      z-index: 1;
      content: '';
      top: 50%;
      right: 0;
      height: 1px;
      width: 3rem;
      margin-left: 1em;
      border-top: 0.2rem solid ${theme.cLight};
    }

    &::after {
      position: static;
      width: 100%;
      border-width: 1px;
    }

    a {
      margin-left: 0.5em;
      color: ${theme.cWhite};

      &:hover {
        text-decoration: none;
      }

      &:focus {
        outline: 2px solid ${theme.cWhite};
        outline-offset: 0.2em;
      }
    }
  }
`;

const Footer = () => (
  <footer css={footerStyle}>
    <div className="content-container">
      © {new Date().getFullYear()}, <Link to="/">Vestiges of War</Link>
    </div>
  </footer>
);

export default Footer;
