import React from 'react';
import { graphql, Link } from 'gatsby';
import ItemList from '../components/ItemList';
import Layout from '../components/Layout';
import { css } from '@emotion/core';
import { LiveMessage } from 'react-aria-live';
import Helmet from 'react-helmet';

const listStyle = theme => css`
  border-bottom: 1px solid ${theme.cDark};
  margin-bottom: 2em;
`;

const CategoryItemsTemplate = ({ data, location, pageContext }) => {
  const items = data.items
    ? data.items.edges.map(({ node }) => ({
        title: node.title,
        slug: `${node.category.mainCategory.slug}/${node.category.slug}/${
          node.slug
        }`,
        thumbnail: node.thumbnails[0],
        datePublished: node.datePublished,
      }))
    : [];

  const BreadCrumb = () => (
    <nav aria-label="breadcrumb" className="breadcrumb">
      <Link to="/">Home</Link>
      <span aria-hidden="true">→</span>
      <Link to={`/${pageContext.mainCategorySlug}`}>
        {pageContext.mainCategoryTitle}
      </Link>
    </nav>
  );
  return (
    <Layout location={location}>
      <LiveMessage
        message={`Navigated to subcategory ${pageContext.title}`}
        aria-live="assertive"
      />
      <Helmet>
        <title>{`Vestiges of War | ${pageContext.title}`}</title>
      </Helmet>
      <BreadCrumb />
      <div css={listStyle}>
        <ItemList items={items} heading={pageContext.title} />
      </div>
      <BreadCrumb />
    </Layout>
  );
};

export default CategoryItemsTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    items: allDatoCmsItem(
      filter: { category: { id: { eq: $id } } }
      sort: { fields: [title], order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug
          datePublished(formatString: "MMMM DD, YYYY")
          category {
            title
            slug
            mainCategory {
              title
              slug
            }
          }
          thumbnails: images {
            url
            fluid(maxWidth: 100, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`;
