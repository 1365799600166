import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import { css } from '@emotion/core';

const style = theme => css`
  background-color: ${theme.cDark};
  padding-top: 5em;
  padding-bottom: 5em;

  h1 {
    font-size: 3em;

    ${theme.mqMax['large']} {
      padding: 0.3em;
    }
  }
  a {
    color: ${theme.cWhite};
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
      outline: none;
    }
  }
  .content-container {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    padding-top: 0.1em;
    padding-bottom: 0.1em;

    .position-container {
      max-width: 60em;
      margin: auto;
    }
  }
`;

const query = graphql`
  query HeaderQuery {
    site {
      siteMetadata {
        title
      }
    }
    datoCmsSiteInfo {
      banner {
        url
        fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;

const Header = ({ title }) => (
  <StaticQuery
    query={query}
    render={({ datoCmsSiteInfo, site }) => {
      const { banner } = datoCmsSiteInfo;
      return (
        <header
          css={style}
          style={{
            backgroundImage: `url("${banner.url}")`,
            backgroundPosition: 'center',
          }}
        >
          <div className="content-container">
            <div className="position-container">
              <h1>
                <Link to="/">{site.siteMetadata.title}</Link>
              </h1>
            </div>
          </div>
        </header>
      );
    }}
  />
);

export default Header;
