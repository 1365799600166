import React from 'react';
import { MDXProvider } from '@mdx-js/tag';
import Image from './Image';
import Header from './Header';
import Footer from './Footer';
import Helmet from 'react-helmet';
import { Global, css } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';
import { darken, lighten } from 'polished';

const breakPoints = {
  tiny: 480,
  small: 640,
  medium: 768,
  large: 1024,
  xLarge: 1200,
};

const mqMax = Object.keys(breakPoints).reduce((mqObj, key) => {
  mqObj[key] = `@media screen and (max-width: ${breakPoints[key]}px)`;
  return mqObj;
}, {});
const mqMin = Object.keys(breakPoints).reduce((mqObj, key) => {
  mqObj[key] = `@media screen and (min-width: ${breakPoints[key]}px)`;
  return mqObj;
}, {});

const cNormal = '#efefef';
const cBlack = '#000000';
const cWhite = '#ffffff';
const cBreadLink = '#030D38';
const cError = '#b80000';

const theme = {
  cNormal,
  cLight: lighten(0.03, cNormal),
  cDark: darken(0.5, cNormal),
  cBlack,
  cWhite,
  cBreadLink,
  cError,
  mqMax,
  mqMin,
  bpTiny: '480px',
  bpSmall: '640px',
  bpMedium: '768px',
  bpLarge: '1024px',
  bpXlarge: '1200px',
};

const styles = css`
  * {
    font-family: Georgia;
  }

  *:focus {
    outline: 2px solid ${theme.cDark};
    outline-offset: 0.1em;
  }

  a:hover {
    text-decoration: none;
  }

  body {
    margin: 0;
    font-size: 100%;
    box-sizing: border-box;
  }

  main {
    max-width: 60em;
    margin-left: auto;
    margin-right: auto;
    padding: 2em;
    background-color: ${theme.cLight};
  }

  ul {
    list-style: none;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    word-wrap: break-word;
  }

  section {
    h2 {
      font-size: 2.23em;
      border-bottom: 1px solid ${theme.cBlack};
    }

    h3 {
      font-size: 1.5em;
    }
  }

  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.2em;
    font-weight: bold;
    line-height: 1.5em;
    a {
      margin-right: 0.5em;
      display: block;
      color: ${theme.cBreadLink};

      &:visited {
        color: ${theme.cBreadLink};
      }
    }
    span {
      margin-right: 0.5em;
    }
  }
`;

const components = {
  img: props => <Image {...props} />,
  h1: ({ children, ...props }) => <h3 {...props}>{children}</h3>,
  h2: ({ children, ...props }) => <h3 {...props}>{children}</h3>,
  p: ({ children, ...props }) =>
    typeof children !== 'string' &&
    ((Array.isArray(children) &&
      children.every(
        child =>
          typeof child !== 'string' && child.props && child.props.name === 'img'
      )) ||
      (children.props && children.props.name === 'img')) ? (
      children
    ) : (
      <p {...props}>{children}</p>
    ),
  strong: 'b',
};

class Layout extends React.Component {
  render() {
    const { title, children } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Helmet>
          <html lang="en" />
        </Helmet>
        <Global styles={styles} />
        <MDXProvider components={components}>
          <Header title={title} />
          <main>{children}</main>
        </MDXProvider>
        <Footer />
      </ThemeProvider>
    );
  }
}

export default Layout;
